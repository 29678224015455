const SEO = [
	{
		page: "home",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "about",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "articles",
		description:
			"Some articles I've written about topics regarding CS which I think are pretty cool.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "projects",
		description:
			"So these are the projects I have worked on during my Computer Science journey. These are simply ideas I've came up with and had the initiative to build them into tools I can use. I feel like the ones you can see here are pretty cool so feel free to click on them.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Tharindu", "Tharindu N", "Tharindu Nayanajith"],
	},
];

export default SEO;
